import React from 'react';
import PropTypes from 'prop-types';

const ErrorCircleFilled = (props) => {
    const { className, style, title } = props;

    return (
        <svg className={className} style={style} width="28px" height="28px" viewBox="0 0 28 28">
            <title>{title}</title>
            <g id="notifications" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="notifications/see-all-activities-show-details"
                    transform="translate(-677.000000, -98.000000)"
                    fill="currentColor"
                >
                    <path
                        d="M691,98 C698.731986,98 705,104.268014 705,112 C705,119.731986 698.731986,126 691,126 C683.268014,126 677,119.731986 677,112 C677,104.268014 683.268014,98 691,98 Z M691.085017,116.840504 C690.641144,116.840504 690.265613,116.993747 689.959227,117.300132 C689.653243,117.60692 689.5,117.978737 689.5,118.415786 C689.5,118.915958 689.659967,119.305338 689.980603,119.583223 C690.300537,119.861409 690.676068,120 691.105991,120 C691.52879,120 691.898299,119.859101 692.215222,119.578005 C692.532948,119.296005 692.69141,118.908833 692.69141,118.415786 C692.69141,117.978637 692.534453,117.606518 692.220942,117.300132 C691.90703,116.993345 691.529191,116.840504 691.085017,116.840504 Z M691.169516,104 C690.669645,104 690.265613,104.163379 689.959227,104.491943 C689.653243,104.819404 689.5,105.27562 689.5,105.860292 C689.5,106.289815 689.531211,106.998024 689.594635,107.98452 L689.594635,107.98452 L689.932934,113.046358 C689.996058,113.70158 690.101933,114.18971 690.250259,114.509744 C690.397782,114.830782 690.661817,114.990347 691.042566,114.990347 C691.415488,114.990347 691.683638,114.825061 691.845813,114.494289 C692.008389,114.162714 692.113963,113.687329 692.163137,113.067031 L692.163137,113.067031 L692.617849,107.856868 C692.666622,107.378874 692.69141,106.906098 692.69141,106.44085 C692.69141,105.652657 692.588946,105.048114 692.385024,104.628628 C692.181101,104.209543 691.775564,104 691.169516,104 Z"
                        id="warning"
                    />
                </g>
            </g>
        </svg>
    );
};

ErrorCircleFilled.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

ErrorCircleFilled.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default ErrorCircleFilled;
