import React from 'react';
import PropTypes from 'prop-types';

const Download = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="18px"
            height="18px"
            viewBox="0 0 18 18"
        >
            <title>{title}</title>
            <g id="Transactions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="transactions/normal-user"
                    transform="translate(-1367.000000, -108.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                >
                    <g id="Group-7" transform="translate(1356.000000, 97.000000)">
                        <path
                            d="M28.3002838,22.0756757 C28.6824189,22.0756757 28.9921892,22.3854459 28.9921892,22.7675811 L28.9921892,22.7675811 L28.9921892,26.9189324 C28.9921892,28.0652973 28.0628784,28.9946081 26.9165135,28.9946081 L26.9165135,28.9946081 L13.0786486,28.9946081 C11.9322838,28.9946081 11.002973,28.0652973 11.002973,26.9189324 L11.002973,26.9189324 L11.002973,22.7675811 C11.002973,22.3854459 11.3127432,22.0756757 11.6948784,22.0756757 C12.0770135,22.0756757 12.3867838,22.3854459 12.3867838,22.7675811 L12.3867838,22.7675811 L12.3867838,26.9189324 C12.3867838,27.3010676 12.6965541,27.6108378 13.0786892,27.6108378 L13.0786892,27.6108378 L26.916473,27.6108378 C27.2986081,27.6108378 27.6083784,27.3010676 27.6083784,26.9189324 L27.6083784,26.9189324 L27.6083784,22.7675811 C27.6083784,22.3854459 27.9181486,22.0756757 28.3002838,22.0756757 Z M19.9975811,11 C20.3797162,11 20.6894865,11.3097703 20.6894865,11.6919054 L20.6894865,11.6919054 L20.6894865,23.0946216 L22.9692838,20.8162432 C23.2373784,20.5573108 23.6624459,20.5573108 23.9305811,20.8162432 C24.2054459,21.0817027 24.2130676,21.5197027 23.9475676,21.7945676 L23.9475676,21.7945676 L20.4881216,25.2540135 C20.4876757,25.2544595 20.4872703,25.2548649 20.4868649,25.2552703 C20.2163378,25.5251081 19.7782568,25.5245405 19.5083784,25.2540135 L19.5083784,25.2540135 L16.0489324,21.7945676 C15.7899595,21.5264324 15.7899595,21.1013649 16.0489324,20.8332297 C16.3143919,20.5584054 16.7523919,20.5507838 17.0272568,20.8162432 L17.0272568,20.8162432 L19.3056757,23.0946622 L19.3056757,11.6919054 C19.3056757,11.3097703 19.6154459,11 19.9975811,11 Z"
                            id="download"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Download.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};
Download.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Download;
