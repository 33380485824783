import React from 'react';
import PropTypes from 'prop-types';

const DocumentPath = (props) => {
    const { className, style, title } = props;

    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
        >
            <title>{title}</title>
            <g id="install-functions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="install-function/trigger-firestore"
                    transform="translate(-645.000000, -959.000000)"
                    fill="currentColor"
                >
                    <path
                        d="M660.753771,959.019815 C662.21162,959.028668 662.977644,959.874372 662.980174,961.364262 C662.988184,965.821705 662.989449,970.27957 662.979752,974.737434 C662.976379,976.258521 662.244925,976.984916 660.720887,976.993348 C658.47467,977.005996 656.228452,976.996721 653.981813,976.996721 C651.770587,976.997143 649.559362,977.004731 647.348136,976.994613 C645.751163,976.987024 645.010856,976.270747 645.007483,974.705394 C644.997787,970.247951 644.996943,965.790086 645.008326,961.332643 C645.012121,959.86847 645.812293,959.025717 647.275623,959.018128 C651.768058,958.994519 656.261336,958.992833 660.753771,959.019815 Z M655.153123,971.015282 L649.180636,971.015282 C648.850784,971.015282 648.583387,971.274814 648.583387,971.594964 L648.583387,972.75433 C648.583387,973.074479 648.850784,973.334012 649.180636,973.334012 L655.153123,973.334012 C655.482975,973.334012 655.750372,973.074479 655.750372,972.75433 L655.750372,971.594964 C655.750372,971.274814 655.482975,971.015282 655.153123,971.015282 Z M658.912277,966.588615 L649.215768,966.588615 C648.866514,966.588615 648.583387,966.848147 648.583387,967.168297 L648.583387,968.327662 C648.583387,968.647812 648.866514,968.907345 649.215768,968.907345 L658.912277,968.907345 C659.261532,968.907345 659.544658,968.647812 659.544658,968.327662 L659.544658,967.168297 C659.544658,966.848147 659.261532,966.588615 658.912277,966.588615 Z M658.912277,962.161947 L649.215768,962.161947 C648.866514,962.161947 648.583387,962.42148 648.583387,962.74163 L648.583387,963.900995 C648.583387,964.221145 648.866514,964.480678 649.215768,964.480678 L658.912277,964.480678 C659.261532,964.480678 659.544658,964.221145 659.544658,963.900995 L659.544658,962.74163 C659.544658,962.42148 659.261532,962.161947 658.912277,962.161947 Z"
                        id="document-path"
                    />
                </g>
            </g>
        </svg>
    );
};

DocumentPath.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

DocumentPath.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default DocumentPath;
