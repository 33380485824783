import React from 'react';
import PropTypes from 'prop-types';

const MoneyFilled = (props) => {
    const { className, style, title } = props;

    return (
        <svg className={className} style={style} width="32px" height="32px" viewBox="0 0 32 32">
            <title>{title}</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M27.3136875,4.68625 C24.29175,1.6643125 20.2738125,0 16,0 C11.72625,0 7.7083125,1.6643125 4.6863125,4.68625 C1.6643125,7.70825 0,11.7261875 0,16 C0,20.2738125 1.66425,24.29175 4.6863125,27.31375 C7.7083125,30.3356875 11.72625,32 16,32 C20.2738125,32 24.29175,30.3356875 27.31375,27.31375 C30.33575,24.29175 32,20.2738125 32,16 C32,11.7261875 30.33575,7.70825 27.3136875,4.68625 Z M17.00425,22.9625 L16.9375,22.9625 L16.9375,25.0375625 C16.9375,25.5553125 16.5178125,25.9750625 16,25.9750625 C15.4823125,25.9750625 15.0625,25.5553125 15.0625,25.0375625 L15.0625,22.9625 L12.9875625,22.9625 C12.469875,22.9625 12.0500625,22.54275 12.0500625,22.025 C12.0500625,21.50725 12.469875,21.0875 12.9875625,21.0875 L17.0043125,21.0875 C18.1484375,21.0875 19.07925,20.156625 19.07925,19.0125 C19.07925,17.868375 18.148375,16.9375 17.00425,16.9375 L14.9958125,16.9375 C12.8178125,16.9375 11.045875,15.1655625 11.045875,12.9875625 C11.045875,10.8095 12.817875,9.0375625 14.9958125,9.0375625 L15.0625,9.0375625 L15.0625,6.9625625 C15.0625,6.4448125 15.4823125,6.0250625 16,6.0250625 C16.5178125,6.0250625 16.9375,6.4448125 16.9375,6.9625625 L16.9375,9.0375625 L19.0125625,9.0375625 C19.53025,9.0375625 19.9500625,9.4573125 19.9500625,9.9750625 C19.9500625,10.4928125 19.53025,10.9125625 19.0125625,10.9125625 L14.9958125,10.9125625 C13.851625,10.9125625 12.920875,11.843375 12.920875,12.9875625 C12.920875,14.1316875 13.8516875,15.0625 14.9958125,15.0625 L17.00425,15.0625 C19.18225,15.0625 20.9541875,16.8345 20.9541875,19.0125 C20.9541875,21.1905 19.18225,22.9625 17.00425,22.9625 Z"
                    id="money-filled"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
};

MoneyFilled.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

MoneyFilled.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default MoneyFilled;
