import React from 'react';
import PropTypes from 'prop-types';

const Camera = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            width="16px"
            height="13px"
            viewBox="0 0 16 13"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
        >
            <title>{title}</title>
            <g id="components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Components-Drawer-Open-with-title"
                    transform="translate(-814.000000, -184.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                >
                    <path
                        d="M827.503751,186.232188 L825.366732,186.232188 L825.366732,186.012642 C825.366732,185.031793 824.570681,184.235742 823.589832,184.235742 L820.191214,184.235742 C819.20997,184.235742 818.414314,185.031793 818.414314,186.012642 L818.414314,186.232188 L816.2769,186.232188 C815.295656,186.232188 814.5,187.027845 814.5,188.009088 L814.5,194.458842 C814.5,195.439691 815.295656,196.235742 816.2769,196.235742 L827.504541,196.235742 C828.485785,196.235742 829.281441,195.439691 829.281441,194.458842 L829.281441,188.009088 C829.280652,187.02745 828.484995,186.232188 827.503751,186.232188 Z M821.889931,194.392504 C820.038796,194.392504 818.533564,192.887272 818.533564,191.036137 C818.533564,189.185396 820.038796,187.67977 821.889931,187.67977 C823.741066,187.67977 825.246298,189.185002 825.246298,191.036137 C825.246298,192.887272 823.740671,194.392504 821.889931,194.392504 Z M823.666831,191.036137 C823.666831,192.015011 822.8692,192.813037 821.889931,192.813037 C820.910661,192.813037 820.113031,192.015011 820.113031,191.036137 C820.113031,190.056867 820.910661,189.259237 821.889931,189.259237 C822.8692,189.259237 823.666831,190.056867 823.666831,191.036137 Z"
                        id="photo"
                    />
                </g>
            </g>
        </svg>
    );
};

Camera.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Camera.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Camera;
