import React from 'react';
import { IconProps } from '../../types';

const JavascriptFile: React.FC<IconProps> = (props: IconProps) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="21px"
            height="20px"
            viewBox="0 0 21 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>{title}</title>
            <g id="marketplace" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="marketplace/functions-source-code/download"
                    transform="translate(-240.000000, -463.000000)"
                >
                    <g id="js" transform="translate(240.611537, 462.999936)">
                        <path
                            d="M19.9808163,10.0253316 C19.9808163,12.8575564 19.9467936,15.6904481 19.9988283,18.5213391 C20.0168403,19.5296565 19.8387216,20.0111414 18.6559339,20 C12.8780856,19.9424531 7.09890319,19.9504556 1.32038785,19.9958032 C0.303043599,20.0038057 -0.00849722978,19.7270519 0,18.6913926 C0.0462058709,12.8608908 0.0442045379,7.02905522 0.000842323993,1.19788652 C-0.00649589683,0.223579851 0.315051597,0.000843077402 1.23433053,0.00684496651 C7.06821608,0.0435231777 12.9027687,0.0541932028 18.7359872,0 C19.8333848,-0.00982694768 20.015506,0.405637154 19.9988283,1.36193815 C19.9481278,4.24884681 19.9808163,7.13708922 19.9808163,10.0253316"
                            id="Path"
                            fill="#FBC728"
                        />
                        <path
                            d="M15.2062418,18.3657761 C14.2189084,18.3644428 13.3309084,18.0877761 12.6302418,17.3964428 C12.3595751,17.1297761 11.7215751,16.8291095 12.1922418,16.3624428 C12.5575751,15.9997761 13.0542418,15.3584428 13.7289084,16.0117761 C14.0522418,16.3251095 14.3975751,16.6011095 14.8589084,16.6564428 C15.4849084,16.7317761 16.1429084,16.7457761 16.3795751,16.0124428 C16.5942418,15.3504428 16.0995751,15.0084428 15.5829084,14.7471095 C14.9435751,14.4231095 14.2622418,14.1671095 13.6642418,13.7804428 C12.7629084,13.1977761 12.2782418,12.3211095 12.4542418,11.2364428 C12.6135751,10.2511095 13.1602418,9.52844281 14.1902418,9.22444281 C15.3475751,8.88244281 16.3795751,9.07510948 17.2835751,9.85644281 C17.5082418,10.0504428 17.8755751,10.3871095 17.5162418,10.6711095 C17.1369084,10.9711095 16.7649084,11.5924428 16.1082418,11.1064428 C15.5295751,10.6777761 14.8335751,10.3357761 14.4422418,11.1724428 C13.9969084,12.1264428 14.9435751,12.3411095 15.5462418,12.6697761 C15.8849084,12.8537761 16.2509084,12.9871095 16.5975751,13.1571095 C17.9502418,13.8191095 18.6502418,14.8871095 18.2755751,16.3837761 C17.8889084,17.9317761 16.6242418,18.3177761 15.2062418,18.3657761"
                            id="Fill-7"
                            fill="#21252B"
                        />
                        <path
                            d="M10.8335084,12.9021761 C10.8335084,13.8415095 10.8588418,14.7821761 10.8268418,15.7201761 C10.7895084,16.8541761 10.3615084,17.7915095 9.2101751,18.1715095 C8.0401751,18.5581761 6.90284176,18.4408428 5.9501751,17.5868428 C5.65750843,17.3255095 5.1081751,16.9641761 5.5941751,16.5235095 C5.9421751,16.2075095 6.3261751,15.7428428 7.0281751,16.1135095 C8.65884176,16.9748428 8.94884176,16.7708428 8.96750843,14.9155095 C8.9821751,13.3681761 8.97950843,11.8201761 8.96684176,10.2728428 C8.9621751,9.67617615 8.97484176,9.19150948 9.8041751,9.18350948 C10.5955084,9.17550948 10.9001751,9.46084281 10.8588418,10.2495095 C10.8135084,11.1321761 10.8481751,12.0181761 10.8481751,12.9021761 L10.8335084,12.9021761 Z"
                            id="Fill-9"
                            fill="#21252B"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

JavascriptFile.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default JavascriptFile;
