import React from 'react';
import PropTypes from 'prop-types';

const Visa = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            width="51px"
            height="36px"
            viewBox="0 0 51 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>{title}</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="rainbow-UniversalPicker-"
                    transform="translate(-350.000000, -211.000000)"
                    fillRule="nonzero"
                >
                    <g id="visa" transform="translate(350.750000, 211.950000)">
                        <path
                            d="M46.04875,33.85 L3.12730634,33.85 C1.53820423,33.85 0.25,32.5605141 0.25,30.9698532 L0.25,3.13005166 C0.25,1.53939073 1.53820423,0.25 3.12730634,0.25 L46.04875,0.25 C47.6379472,0.25 48.9260563,1.53948589 48.9260563,3.13005166 L48.9260563,30.9698532 C48.9260563,32.5605141 47.6378521,33.85 46.04875,33.85 Z"
                            id="Shape"
                            fill="#FBFBFC"
                        />
                        <path
                            d="M46.04875,1.88293825e-13 L3.12730634,1.88293825e-13 C1.40006551,1.88293825e-13 1.70530257e-13,1.40138703 1.70530257e-13,3.13005166 L1.70530257e-13,30.9698532 C1.70530257e-13,32.6985334 1.40008109,34.1 3.12730634,34.1 L46.04875,34.1 C47.7759753,34.1 49.1760563,32.6985334 49.1760563,30.9698532 L49.1760563,3.13005166 C49.1760563,1.40141901 47.7760228,1.88293825e-13 46.04875,1.88293825e-13 Z M3.12730634,0.5 L46.04875,0.5 C47.4997696,0.5 48.6760563,1.67745074 48.6760563,3.13005166 L48.6760563,30.9698532 C48.6760563,32.4225017 47.4997221,33.6 46.04875,33.6 L3.12730634,33.6 C1.67633423,33.6 0.5,32.4225017 0.5,30.9698532 L0.5,3.13005166 C0.5,1.67742386 1.67631354,0.5 3.12730634,0.5 Z"
                            id="border"
                            fill="#EDEFF2"
                        />
                        <g id="Group-4" transform="translate(2.683803, 9.683333)">
                            <path
                                d="M8.51830986,7.2 L7.28653713,2.13395605 C7.07598207,1.43533373 6.46327196,1.22749513 5.6976917,1.2 L0.0503078032,1.2 L9.76996262e-14,1.4314686 C4.39850037,2.34565978 7.30887339,4.55106804 8.51830986,7.2 Z"
                                id="Shape"
                                fill="#F7981D"
                            />
                            <g id="Group" transform="translate(3.650704, -0.000000)" fill="#293688">
                                <polygon
                                    id="Shape"
                                    points="16.8996602 0.935969868 13.5082304 0.935969868 11.3901224 13.2266478 14.7759336 13.2266478"
                                />
                                <path
                                    d="M6.80441181,13.2143126 L12.1382501,0.944350282 L8.54893215,0.944350282 L5.23082952,9.32740113 L4.87666875,8.06167608 C4.22624712,6.53898305 2.36735543,4.34736347 0.185062293,2.96770245 L3.21814121,13.2184557 L6.80441181,13.2143126 Z"
                                    id="Shape"
                                />
                                <path
                                    d="M26.5728488,9.20885122 C26.5854192,7.81346516 25.6793047,6.74915254 23.7267066,5.8700565 C22.5400443,5.30225989 21.8167716,4.92363465 21.8254375,4.34745763 C21.8254375,3.83841808 22.4379576,3.29161959 23.7650844,3.29161959 C24.8755626,3.2747646 25.6751146,3.51450094 26.3004907,3.76271186 L26.6069412,3.90160075 L27.0665217,1.23898305 C26.3942924,0.990677966 25.3434282,0.725706215 24.0291574,0.725706215 C20.6811526,0.725706215 18.3244931,2.39152542 18.3031616,4.7806968 C18.28183,6.54745763 19.9879726,7.53173258 21.2725315,8.12052731 C22.5911828,8.72071563 23.0336219,9.10480226 23.0251464,9.64322034 C23.0166709,10.4664783 21.9742822,10.8419021 21.0001734,10.8419021 C19.6431444,10.8419021 18.9241571,10.6568738 17.8091078,10.1975518 L17.3758108,10.0007533 L16.8998507,12.7548964 C17.6905463,13.0966102 19.1537569,13.3952919 20.6726771,13.4077213 C24.2330451,13.4077213 26.5473272,11.7630885 26.5728488,9.20885122 Z"
                                    id="Shape"
                                />
                                <path
                                    d="M30.6012728,11.3844633 C30.9883832,11.3844633 34.446855,11.3887006 34.9405279,11.3887006 C35.0426146,11.8166667 35.3531599,13.2309793 35.3531599,13.2309793 L38.496801,13.2309793 L35.7529359,0.948681733 L33.1367744,0.948681733 C32.3241759,0.948681733 31.7203218,1.16741996 31.362828,1.96647834 L26.330393,13.2309793 L29.8912372,13.2309793 C29.8909515,13.2309793 30.4693791,11.7169492 30.6012728,11.3844633 Z M32.9238399,5.43248588 C32.9070794,5.46610169 33.2047687,4.72165725 33.3749449,4.25894539 L33.6045447,5.31883239 C33.6045447,5.31883239 34.2513476,8.25499058 34.3873362,8.86911488 L31.5753816,8.86911488 C31.8562152,8.16252354 32.9238399,5.43248588 32.9238399,5.43248588 Z"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Visa.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Visa.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Visa;
