import React from 'react';
import { IconProps } from '../../types';

const ReadmeFile: React.FC<IconProps> = (props: IconProps) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="21px"
            height="20px"
            viewBox="0 0 21 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>{title}</title>
            <g id="marketplace" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="marketplace/functions-source-code/download"
                    transform="translate(-183.000000, -537.000000)"
                    fill="#41A3F1"
                >
                    <path
                        d="M193.660647,543.711376 C193.737706,543.032553 193.635353,542.584906 192.776529,542.599024 C192.048294,542.610788 191.715353,542.799612 191.734176,543.579024 C191.749471,544.243141 191.807118,544.683729 192.663,544.647259 C193.353588,544.616671 193.824765,544.533729 193.660647,543.711376 M193.670647,549.553729 C193.670647,548.870788 193.644765,548.187259 193.678294,547.506671 C193.710647,546.840788 193.488882,546.521965 192.770059,546.540788 C192.144765,546.556671 191.739471,546.676671 191.753588,547.422553 C191.779471,548.786671 191.780059,550.151965 191.753,551.516671 C191.738294,552.253141 192.123,552.396082 192.758882,552.409612 C193.467118,552.424906 193.715353,552.131376 193.678294,551.453729 C193.643,550.822553 193.670647,550.187259 193.670647,549.553729 M192.567706,557.257259 C187.251235,557.1802 182.900059,552.685494 183.001824,547.375494 C183.105353,541.972553 187.477118,537.744318 192.866529,537.836082 C198.258882,537.926671 202.541824,542.319024 202.464176,547.676082 C202.387118,552.966082 197.875941,557.333729 192.567706,557.257259"
                        id="readme"
                    />
                </g>
            </g>
        </svg>
    );
};

ReadmeFile.defaultProps = {
    className: undefined,
    style: undefined,
};

export default ReadmeFile;
