import React from 'react';
import PropTypes from 'prop-types';

export default function CampaignFilled(props) {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            width="25px"
            height="24px"
            viewBox="0 0 25 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <title>{title}</title>
            <g id="Dashboard" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
                <path
                    d="M12.2843838,19.1229875 C12.9125212,19.7551632 13.1173725,20.4427736 12.8950831,21.2902635 C12.7906383,21.6887691 12.7273106,22.0981047 12.637734,22.5008322 C12.3229311,23.9155181 10.9078781,24.4605035 9.73145123,23.6042028 C8.30556829,22.5665462 7.33858176,21.1731531 6.67887228,19.5549008 C6.1843655,18.3423129 5.88167742,17.0808985 5.80990602,15.7726766 C5.78953102,15.4048252 5.65131142,15.2950572 5.29484069,15.2572441 C2.70629779,14.9819062 0.813074733,12.7520368 0.95184501,10.1710198 C1.0887797,7.62487892 3.2090647,5.62335631 5.79815828,5.60206352 C6.66987791,5.59472117 7.54526871,5.55121779 8.41276649,5.61179212 C9.76999853,5.70632478 11.047199,5.39078761 12.231886,4.81790132 C14.3071992,3.81401952 16.3318502,2.70514222 18.3750406,1.6353629 C19.2552039,1.17444734 20.1168278,0.677003624 21.0081882,0.239400002 C22.3654202,-0.427101152 24.0268088,0.451043029 24.2799361,1.94337417 C24.307837,2.10857688 24.3278449,2.27763432 24.3280284,2.44467262 C24.3302311,7.81339356 24.3355543,13.1819309 24.325275,18.5506519 C24.3228888,19.727813 23.6339935,20.585215 22.5034563,20.9044234 C21.9079923,21.072563 21.3642918,20.9572883 20.8396814,20.6320225 C19.1891228,19.6092341 17.5413176,18.5816733 15.8703839,17.5930269 C14.4579008,16.7571011 12.9518027,16.1194187 11.3735662,15.6594209 C11.2303905,15.6177531 11.0842779,15.5859975 10.8783252,15.534234 C11.0303116,16.8949536 11.3218027,18.1539818 12.2843838,19.1229875 Z"
                    id="campaign-filled"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}

CampaignFilled.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};
CampaignFilled.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};
