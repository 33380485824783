import React from 'react';
import PropTypes from 'prop-types';

const Roles = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            width="32px"
            height="26px"
            viewBox="0 0 40 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>{title}</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="roles" transform="translate(-0.000100, -0.000336)" fill="currentColor">
                    <path
                        d="M19.5987692,29.5463682 C23.4945641,29.5463682 27.3911795,29.6021631 31.2853333,29.5299579 C34.9965128,29.4618554 36.7433846,27.611599 36.7540513,23.8799067 C36.769641,18.6171374 36.7671795,13.3551887 36.7548718,8.09323999 C36.7458462,4.20400922 34.8808205,2.35047076 30.9546667,2.34390666 C23.3690256,2.33077845 15.7833846,2.33159896 8.19774359,2.34308614 C4.17641026,2.34965025 2.2785641,4.23190666 2.26707692,8.21631691 C2.25312821,13.34124 2.25476923,18.4669836 2.26625641,23.5927272 C2.27528205,27.6337528 4.07548718,29.4905733 8.11815385,29.5406246 C11.9442051,29.5882143 15.7718974,29.5512913 19.5987692,29.5463682 M19.5692308,31.872522 C15.6726154,31.8749836 11.776,31.9168297 7.88020513,31.8643169 C2.848,31.7970349 0.0336410256,28.9367272 0.0147692308,23.9217528 C-0.00492307692,18.58924 -0.00492307692,13.2567272 0.0147692308,7.92503486 C0.0336410256,2.9609323 2.93251282,0.044829733 7.9294359,0.0234963996 C15.6537436,-0.0093241132 23.3788718,-0.00604206192 31.1031795,0.0226758868 C36.1837949,0.0407271688 39.0728205,2.9806246 39.0834872,8.07682973 C39.0930796,13.4093426 39.0998974,18.7418554 39.0810256,24.0735477 C39.0637949,28.800522 36.1878974,31.771599 31.4633846,31.8552913 C27.4994872,31.9258554 23.5339487,31.8700605 19.5692308,31.872522"
                        id="Fill-1"
                    />
                    <path
                        d="M19.5246695,15.9782861 C19.5410051,12.4673118 16.8505436,9.73664512 13.3666462,9.72677294 C9.92869744,9.71695281 7.08562051,12.5025938 7.06747249,15.8986964 C7.04869744,19.3514143 9.79331282,22.0927477 13.2813128,22.106752 C16.8456205,22.1206451 19.5081846,19.5064913 19.5246695,15.9782861 M13.2337231,24.4550874 C8.56992821,24.4755169 4.73649231,20.70444 4.67741538,16.0390041 C4.61833846,11.3341836 8.5633641,7.36208102 13.2534154,7.40474768 C17.8712615,7.44741435 21.7358769,11.3432092 21.7301397,15.9512092 C21.7243897,20.5887477 17.8827487,24.4328502 13.2337231,24.4550874"
                        id="Fill-6"
                    />
                    <path
                        d="M25.9590564,9.66017332 C24.5788164,9.65360922 24.5788164,7.40440711 25.8252761,7.40440711 C26.5246605,7.40440711 32.4526331,7.40440711 32.9356002,7.40440711 C34.1364513,7.40440711 34.1364513,9.65360922 32.8521846,9.65360922 C30.9409972,9.65360922 27.2407424,9.66626872 25.9590564,9.66017332 Z"
                        id="Fill-8"
                    />
                    <path
                        d="M25.9590564,14.5832502 C24.5788164,14.5766861 24.5788164,12.327484 25.8252761,12.327484 C26.5246605,12.327484 32.4526331,12.327484 32.9356002,12.327484 C34.1364513,12.327484 34.1364513,14.5766861 32.8521846,14.5766861 C30.9409972,14.5766861 27.2407424,14.5893456 25.9590564,14.5832502 Z"
                        id="Fill-8-Copy"
                    />
                    <path
                        d="M25.9590564,19.5063272 C24.5788164,19.4997631 24.5788164,17.250561 25.8252761,17.250561 C26.5246605,17.250561 32.4526331,17.250561 32.9356002,17.250561 C34.1364513,17.250561 34.1364513,19.4997631 32.8521846,19.4997631 C30.9409972,19.4997631 27.2407424,19.5124226 25.9590564,19.5063272 Z"
                        id="Fill-8-Copy-2"
                    />
                    <path
                        d="M25.9590564,24.4294041 C24.5788164,24.42284 24.5788164,22.1736379 25.8252761,22.1736379 C26.5246605,22.1736379 32.4526331,22.1736379 32.9356002,22.1736379 C34.1364513,22.1736379 34.1364513,24.42284 32.8521846,24.42284 C30.9409972,24.42284 27.2407424,24.4354995 25.9590564,24.4294041 Z"
                        id="Fill-8-Copy-3"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Roles;

Roles.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Roles.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};
