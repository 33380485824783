import React from 'react';
import PropTypes from 'prop-types';

const Apps = (props) => {
    const { className, style, title } = props;
    return (
        <svg className={className} style={style} width="24px" height="24px" viewBox="0 0 24 24">
            <title>{title}</title>
            <g id="new-layout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="new-layout/projects"
                    transform="translate(-21.000000, -154.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                >
                    <g id="Group-5" transform="translate(9.000000, 142.000000)">
                        <path
                            d="M33.8181679,25.0908905 C35.0214559,25.0908905 36,26.0693834 36,27.2727226 L36,27.2727226 L36,33.8181679 C36,35.0215071 35.0214559,36 33.8181679,36 L33.8181679,36 L27.2727226,36 C26.0693834,36 25.0908905,35.0214559 25.0908905,33.8181679 L25.0908905,33.8181679 L25.0908905,27.2727226 C25.0908905,26.0693834 26.0693834,25.0908905 27.2727226,25.0908905 L27.2727226,25.0908905 Z M20.7272774,25.0908905 C21.9305654,25.0908905 22.9091095,26.0693834 22.9091095,27.2727226 L22.9091095,27.2727226 L22.9091095,33.8181679 C22.9091095,35.0215071 21.9305654,36 20.7272774,36 L20.7272774,36 L14.1818321,36 C12.9784929,36 12,35.0214559 12,33.8181679 L12,33.8181679 L12,27.2727226 C12,26.0693834 12.9784929,25.0908905 14.1818321,25.0908905 L14.1818321,25.0908905 Z M20.7272774,12 C21.9305654,12 22.9091095,12.9784929 22.9091095,14.1818321 L22.9091095,14.1818321 L22.9091095,20.7272774 C22.9091095,21.9306166 21.9305654,22.9091095 20.7272774,22.9091095 L20.7272774,22.9091095 L14.1818321,22.9091095 C12.9784929,22.9091095 12,21.9305654 12,20.7272774 L12,20.7272774 L12,14.1818321 C12,12.9784929 12.9784929,12 14.1818321,12 L14.1818321,12 Z M33.8181679,12 C35.0214559,12 36,12.9784929 36,14.1818321 L36,14.1818321 L36,20.7272774 C36,21.9306166 35.0214559,22.9091095 33.8181679,22.9091095 L33.8181679,22.9091095 L27.2727226,22.9091095 C26.0693834,22.9091095 25.0908905,21.9305654 25.0908905,20.7272774 L25.0908905,20.7272774 L25.0908905,14.1818321 C25.0908905,12.9784929 26.0693834,12 27.2727226,12 L27.2727226,12 Z"
                            id="Combined-Shape"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Apps.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Apps.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Apps;
