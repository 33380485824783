import React from 'react';
import PropTypes from 'prop-types';

const Cancel = (props) => {
    const { className, style, title } = props;
    return (
        <svg className={className} style={style} width="100px" height="100px" viewBox="0 0 100 100">
            <title>{title}</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="cancel" fillRule="nonzero">
                    <circle
                        id="Oval"
                        fill="#FE4849"
                        cx="49.9026316"
                        cy="49.9026316"
                        r="49.9026316"
                    />
                    <path
                        d="M99.8050439,49.9026316 C99.8050439,77.3491228 77.3489035,99.8052632 49.9024123,99.8052632 C33.9960526,99.8052632 19.9609649,92.6317982 10.9160088,81.091886 C19.3370614,87.9535088 30.2532895,92.008114 42.1050439,92.008114 C69.5515351,92.008114 92.0076754,69.5519737 92.0076754,42.1054825 C92.0076754,30.2537281 87.9530702,19.3375 81.0914474,10.9164474 C92.6315789,19.9609649 99.8050439,33.9960526 99.8050439,49.9026316 Z"
                        id="Shape"
                        fill="#EA4243"
                    />
                    <path
                        d="M46.6235033,53.9238426 L45.3548826,34.9419505 C45.1170398,31.2425909 45,28.5868045 45,26.9760965 C45,24.7835768 45.5746615,23.0727641 46.7221027,21.8447875 C47.8710493,20.6126712 49.3861685,20 51.2606863,20 C53.5333651,20 55.0541293,20.7857846 55.8188393,22.3573539 C56.5835492,23.9304285 56.9677858,26.1974623 56.9677858,29.1531866 C56.9677858,30.8978693 56.8748314,32.6707771 56.6919333,34.4632542 L54.9867656,54.0013673 C54.8023621,56.3274855 54.4064591,58.1101779 53.7967987,59.3535843 C53.1886435,60.5939799 52.18308,61.2138014 50.7846243,61.2138014 C49.3568145,61.2138014 48.3666807,60.6154309 47.8134702,59.4115396 C47.2572491,58.2114117 46.8602171,56.3809249 46.6235033,53.9238426 Z M51.0224671,80 C49.4102538,80 48.0020134,79.4802833 46.8022618,78.4370864 C45.5998758,77.3950186 45,75.9348441 45,74.0591973 C45,72.4202643 45.5746615,71.0259482 46.7221027,69.8754963 C47.8710493,68.7265497 49.2792897,68.1518883 50.9438134,68.1518883 C52.609466,68.1518883 54.0263622,68.7250444 55.2035338,69.8754963 C56.3792,71.0244429 56.9677858,72.419888 56.9677858,74.0591973 C56.9677858,75.9081244 56.373555,77.3600196 55.1820828,78.4175171 C53.9936211,79.4716276 52.6079607,80 51.0224671,80 Z"
                        id="Shape"
                        fill="#FFFFFF"
                    />
                </g>
            </g>
        </svg>
    );
};

Cancel.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Cancel.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Cancel;
