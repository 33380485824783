import React from 'react';
import PropTypes from 'prop-types';

const Phone = (props) => {
    const { className, style, title } = props;

    return (
        <svg className={className} style={style} width="15px" height="15px" viewBox="0 0 15 15">
            <title>{title}</title>
            <g id="edit-rides" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="dispatch/rides-customerInfo"
                    transform="translate(-418.000000, -224.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                >
                    <g id="Group-49" transform="translate(250.000000, 159.000000)">
                        <g id="Group-48" transform="translate(0.000000, 46.000000)">
                            <g id="Group-37" transform="translate(10.000000, 11.000000)">
                                <path
                                    d="M171.951466,20.4607844 C171.95089,20.46136 171.950315,20.461988 171.949687,20.4625636 L171.949687,20.4581157 L171.270942,21.1323597 C170.393143,22.0212524 169.114722,22.3869716 167.899618,22.0968662 C166.675408,21.7691893 165.511638,21.2475856 164.452418,20.5518872 C163.468341,19.9229591 162.556371,19.1877532 161.733046,18.3594037 C160.975496,17.6074006 160.29492,16.7816675 159.701313,15.8945539 C159.05203,14.9399898 158.538118,13.9001313 158.174178,12.8045437 C157.756968,11.5174885 158.102698,10.1052129 159.067257,9.15640481 L159.862064,8.36159751 C160.083045,8.13962288 160.442118,8.13883796 160.66404,8.35981836 C160.664616,8.36039397 160.665244,8.36096957 160.66582,8.36159751 L163.175305,10.8710829 C163.39728,11.0920633 163.398065,11.4511368 163.177084,11.6730591 C163.176509,11.6736347 163.175933,11.6742103 163.175305,11.6748383 L161.701753,13.1483898 C161.278945,13.5665937 161.22578,14.2311571 161.576742,14.7112649 C162.109701,15.4427032 162.699488,16.130971 163.340556,16.7697891 C164.055302,17.4876222 164.832318,18.1406211 165.662499,18.721146 C166.142188,19.0557301 166.792518,18.9993207 167.207478,18.5871867 L168.631893,17.1404271 C168.852874,16.9184525 169.211947,16.9176675 169.43387,17.1386479 C169.434445,17.1392235 169.435021,17.1397991 169.435649,17.1404271 L171.949634,19.6588605 C172.171661,19.8797886 172.172446,20.2388098 171.951466,20.4607844 Z"
                                    id="Path"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Phone.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Phone.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Phone;
