import React from 'react';
import PropTypes from 'prop-types';

const Caution = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            width="53px"
            height="48px"
            viewBox="0 0 53 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>{title}</title>
            <g id="webhook-solution" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="webhooks-log-details-redeliver"
                    transform="translate(-489.000000, -346.000000)"
                    fill="#F4D87A"
                    fillRule="nonzero"
                >
                    <path
                        d="M508.558262,350.036344 C511.566407,344.747656 519.200796,344.747656 522.219801,350.036344 L522.219801,350.036344 L540.703059,382.050824 C543.722063,387.274353 539.899439,393.909647 533.807131,393.909647 L533.807131,393.909647 L496.894914,393.909647 C490.802606,393.909647 487.04514,387.339511 490.064145,382.050824 L490.064145,382.050824 Z M514.97565,380.014005 C513.582702,380.014005 512.42505,381.171657 512.42505,382.564605 C512.42505,383.957552 513.582702,385.115205 514.97565,385.115205 C516.312127,385.115205 517.535662,383.957552 517.469779,382.630487 C517.52625,381.171657 516.368598,380.014005 514.97565,380.014005 Z M517.300367,361.639023 C516.773305,360.48137 515.559182,359.897838 514.335647,360.189604 C513.112112,360.537841 512.359167,361.639023 512.359167,362.975499 C512.42505,363.784915 512.481521,364.603742 512.537992,365.413158 C512.707404,368.490819 512.886229,372.512009 513.055641,375.58967 C513.121524,376.634381 513.930939,377.387326 514.97565,377.387326 C516.020361,377.387326 516.839188,376.57791 516.895659,375.5332 C516.895659,374.902608 516.895659,374.319076 516.95213,373.679073 C517.065071,371.702594 517.187425,368.735526 517.300367,366.759046 C517.356838,365.488452 517.479191,364.208446 517.535662,362.919028 C517.535662,362.448438 517.479191,362.04373 517.300367,361.639023 Z"
                        id="warning"
                    />
                </g>
            </g>
        </svg>
    );
};

Caution.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Caution.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Caution;
