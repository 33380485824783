import React from 'react';
import PropTypes from 'prop-types';

const Discover = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            width="51px"
            height="36px"
            viewBox="0 0 51 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>{title}</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="rainbow-UniversalPicker-"
                    transform="translate(-359.000000, -365.000000)"
                    fillRule="nonzero"
                >
                    <g id="discover" transform="translate(359.750000, 365.950000)">
                        <path
                            d="M46.04875,33.85 L3.12730634,33.85 C1.53820423,33.85 0.25,32.5605141 0.25,30.9698532 L0.25,3.13005166 C0.25,1.53939073 1.53820423,0.25 3.12730634,0.25 L46.04875,0.25 C47.6379472,0.25 48.9260563,1.53948589 48.9260563,3.13005166 L48.9260563,30.9698532 C48.9260563,32.5605141 47.6378521,33.85 46.04875,33.85 Z"
                            id="Shape"
                            fill="#FBFBFC"
                        />
                        <path
                            d="M46.04875,1.88293825e-13 L3.12730634,1.88293825e-13 C1.40006551,1.88293825e-13 1.70530257e-13,1.40138703 1.70530257e-13,3.13005166 L1.70530257e-13,30.9698532 C1.70530257e-13,32.6985334 1.40008109,34.1 3.12730634,34.1 L46.04875,34.1 C47.7759753,34.1 49.1760563,32.6985334 49.1760563,30.9698532 L49.1760563,3.13005166 C49.1760563,1.40141901 47.7760228,1.88293825e-13 46.04875,1.88293825e-13 Z M3.12730634,0.5 L46.04875,0.5 C47.4997696,0.5 48.6760563,1.67745074 48.6760563,3.13005166 L48.6760563,30.9698532 C48.6760563,32.4225017 47.4997221,33.6 46.04875,33.6 L3.12730634,33.6 C1.67633423,33.6 0.5,32.4225017 0.5,30.9698532 L0.5,3.13005166 C0.5,1.67742386 1.67631354,0.5 3.12730634,0.5 Z"
                            id="border"
                            fill="#EDEFF2"
                        />
                        <g id="Group-3" transform="translate(7.281646, 14.182911)">
                            <circle
                                id="Oval"
                                fill="#F26E21"
                                cx="18.9246835"
                                cy="2.96772152"
                                r="2.96012658"
                            />
                            <g id="Group" transform="translate(-0.000000, 0.000000)" fill="#0B161F">
                                <path
                                    d="M2.98167722,4.38512658 C2.63287975,4.69737342 2.18278481,4.83275316 1.46800633,4.83275316 L1.17142405,4.83275316 L1.17142405,1.11636076 L1.46800633,1.11636076 C2.18268987,1.11636076 2.61503165,1.24281646 2.98167722,1.57101266 C3.36389241,1.90813291 3.59193038,2.43 3.59193038,2.96924051 C3.59193038,3.50876582 3.36389241,4.04905063 2.98167722,4.38512658 L2.98167722,4.38512658 Z M1.68968354,0.164620253 L0.0656012658,0.164620253 L0.0656012658,5.78363924 L1.68151899,5.78363924 C2.53908228,5.78363924 3.15892405,5.58256329 3.70281646,5.13503165 C4.34791139,4.60433544 4.73088608,3.80401899 4.73088608,2.97664557 C4.73079114,1.31772152 3.48113924,0.164620253 1.68968354,0.164620253"
                                    id="Shape"
                                />
                                <polyline
                                    id="Path"
                                    points="5.24050633 5.78363924 6.34462025 5.78363924 6.34462025 0.164620253 5.24050633 0.164620253 5.24050633 5.78363924"
                                />
                                <path
                                    d="M9.04756329,2.32015823 C8.3839557,2.07607595 8.18857595,1.91553797 8.18857595,1.61363924 C8.18857595,1.25943038 8.53632911,0.989810127 9.01272152,0.989810127 C9.34414557,0.989810127 9.61556962,1.12443038 9.90579114,1.44379747 L10.4823418,0.694936709 C10.0070886,0.281487342 9.43794304,0.0711075949 8.81705696,0.0711075949 C7.81528481,0.0711075949 7.05009494,0.762056962 7.05009494,1.67924051 C7.05009494,2.45544304 7.40639241,2.85113924 8.44367089,3.22272152 C8.87705696,3.37367089 9.09731013,3.47420886 9.20810127,3.54275316 C9.42873418,3.68629747 9.53990506,3.88765823 9.53990506,4.12357595 C9.53990506,4.57936709 9.17439873,4.91572785 8.68091772,4.91572785 C8.15411392,4.91572785 7.7296519,4.65503165 7.47455696,4.16620253 L6.76196203,4.84927215 C7.27063291,5.58958861 7.88240506,5.91958861 8.72420886,5.91958861 C9.87085443,5.91958861 10.677627,5.16009494 10.677627,4.07382911 C10.6781013,3.18018987 10.3050949,2.77556962 9.04756329,2.32015823"
                                    id="Path"
                                />
                                <path
                                    d="M11.0262342,2.97664557 C11.0262342,4.62987342 12.3349367,5.91047468 14.0173101,5.91047468 C14.4933228,5.91047468 14.9004114,5.81705696 15.4023418,5.58256329 L15.4023418,4.29208861 C14.9601266,4.73079114 14.5689873,4.90737342 14.0677215,4.90737342 C12.9547785,4.90737342 12.1644304,4.10667722 12.1644304,2.96886076 C12.1644304,1.89075949 12.9800316,1.03927215 14.0173101,1.03927215 C14.5430696,1.03927215 14.943038,1.2243038 15.4023418,1.67126582 L15.4023418,0.381740506 C14.9185443,0.138037975 14.5181962,0.0375 14.0421835,0.0375 C12.3689241,0.0378797468 11.0262342,1.34401899 11.0262342,2.97664557"
                                    id="Path"
                                />
                                <polyline
                                    id="Path"
                                    points="24.3565823 3.93901899 22.8439557 0.164620253 21.6375949 0.164620253 24.0429114 5.92756329 24.6368354 5.92756329 27.0843987 0.164620253 25.8862025 0.164620253 24.3565823 3.93901899"
                                />
                                <polyline
                                    id="Path"
                                    points="27.5863291 5.78363924 30.7208544 5.78363924 30.7208544 4.83275316 28.6907278 4.83275316 28.6907278 3.31452532 30.6431013 3.31452532 30.6431013 2.3635443 28.6907278 2.3635443 28.6907278 1.11626582 30.7208544 1.11626582 30.7208544 0.164620253 27.5863291 0.164620253 27.5863291 5.78363924"
                                />
                                <path
                                    d="M32.8778165,2.75107595 L32.555981,2.75107595 L32.555981,1.04810127 L32.8951899,1.04810127 C33.5847152,1.04810127 33.9587658,1.33585443 33.9587658,1.8825 C33.9587658,2.44661392 33.5847152,2.75107595 32.8778165,2.75107595 L32.8778165,2.75107595 Z M35.0951582,1.82287975 C35.0951582,0.770316456 34.3651899,0.164620253 33.0899051,0.164620253 L31.450538,0.164620253 L31.450538,5.78363924 L32.5556962,5.78363924 L32.5556962,3.52547468 L32.7006646,3.52547468 L34.2288608,5.78363924 L35.5883544,5.78363924 L33.8033544,3.41639241 C34.6372785,3.24806962 35.0951582,2.68291139 35.0951582,1.82287975"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Discover.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};

Discover.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Discover;
