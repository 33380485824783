import React from 'react';
import PropTypes from 'prop-types';

const PullRequest = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            className={className}
            style={style}
        >
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                    <g>
                        <path
                            d="M1119.501 12.443c1.295.29 2.184 1.45 2.177 2.838-.017.933-.392 1.712-1.18 2.19-.597.362-.738.805-.721 1.455.041 1.567.036 3.136.001 4.703-.012.547.157.878.618 1.19 1.166.791 1.525 1.902 1.121 3.173-.378 1.188-1.416 1.963-2.645 1.973-1.23.01-2.29-.753-2.679-1.929-.421-1.274-.076-2.42 1.07-3.187.502-.335.668-.698.656-1.278-.034-1.566-.033-3.135-.001-4.703.011-.604-.11-.99-.696-1.342-1.126-.676-1.52-2.267-.987-3.415.593-1.278 1.938-1.964 3.266-1.668zm6.955-.417c.297.11.222.398.233.632.01.208.027.422-.002.627-.11.762.164 1.021.97 1.017 2.203-.011 3.413 1.243 3.425 3.423.006.94.001 1.881.001 2.822 0 .558-.004 1.116 0 1.673.011 1.143-.134 2.185 1.102 3.07 1.257.901 1.002 3.032-.158 3.97-1.299 1.05-3.129.898-4.116-.34-1.002-1.257-.755-3.107.609-4.045.524-.36.709-.738.695-1.363-.042-1.846-.015-3.694-.015-5.54 0-.175.012-.35-.007-.523-.105-.929-.693-1.301-1.795-1.297-.73.003-.744.362-.711.746l.013.153c.011.128.018.254-.006.365-.017.081-.015.17-.008.261l.017.183c.017.21.01.409-.204.516-.313.156-.507-.035-.681-.248l-.115-.142c-.038-.046-.076-.09-.115-.127-.683-.647-1.32-1.343-2.008-1.983-.474-.44-.578-.798-.049-1.287.793-.732 1.535-1.52 2.3-2.282.172-.17.349-.382.625-.28zm-8.536 15.098c-.02.578.32.886.874.961.576-.024.942-.291.982-.872.038-.571-.256-.945-.832-.993-.622-.053-1.003.273-1.024.904zm12.207-.911c-.575.01-.915.344-.914.918.001.628.367.97.994.938.581-.03.851-.4.878-.976-.074-.555-.38-.89-.958-.88zm-12.207-11.016c-.024.578.314.887.865.967.578-.02.947-.284.99-.865.043-.57-.25-.947-.824-1-.62-.056-1.005.267-1.03.898z"
                            transform="translate(-1146 -254) translate(30 242)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

PullRequest.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};
PullRequest.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default PullRequest;
