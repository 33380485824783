import React from 'react';
import PropTypes from 'prop-types';

const Error = (props) => {
    const { className, style, title } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="32px"
            height="32px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>{title}</title>
            <g id="pages" stroke="none" strokwidth="1" fillRule="evenodd">
                <g
                    id="react-rainbow-custom-work-form-error-V1"
                    transform="translate(-42.000000, -20.000000)"
                >
                    <g id="Group-23">
                        <path
                            d="M58,20 C66.836556,20 74,27.163444 74,36 C74,44.836556 66.836556,52 58,52 C49.163444,52 42,44.836556 42,36 C42,27.163444 49.163444,20 58,20 Z M58,21.5266409 C50.0065845,21.5266409 43.5266409,28.0065845 43.5266409,36 C43.5266409,43.9934155 50.0065845,50.4733591 58,50.4733591 C65.9934155,50.4733591 72.4733591,43.9934155 72.4733591,36 C72.4733591,28.0065845 65.9934155,21.5266409 58,21.5266409 Z M58.3179021,41.5720123 C58.8264728,41.5720123 59.2590911,41.747013 59.6185148,42.0982784 C59.9774788,42.4490841 60.1571907,42.8751529 60.1571907,43.3756802 C60.1571907,43.9402097 59.9757553,44.3835142 59.6119652,44.706398 C59.2490944,45.0282477 58.8260132,45.1895746 58.3419173,45.1895746 C57.8496632,45.1895746 57.4196877,45.0308905 57.0533697,44.7123731 C56.6862474,44.3942004 56.5030884,43.948368 56.5030884,43.3756802 C56.5030884,42.8752678 56.6785488,42.4495438 57.0288949,42.0982784 C57.3797007,41.7474726 57.8096762,41.5720123 58.3179021,41.5720123 Z M58.4146524,26.869884 C59.1085653,26.869884 59.5728974,27.1098062 59.8063849,27.5896506 C60.0398724,28.0699546 60.1571907,28.7621439 60.1571907,29.6646098 C60.1571907,30.1973106 60.1288091,30.7386293 60.0729651,31.285923 L59.5523294,37.2514595 C59.4960258,37.9616889 59.3751455,38.5059952 59.1889989,38.8856422 C59.003312,39.2643699 58.6962852,39.4536189 58.2692972,39.4536189 C57.8333467,39.4536189 57.5310309,39.2709195 57.3621202,38.9033376 C57.1922902,38.5369047 57.0710651,37.9780055 56.9987897,37.227789 L56.6114441,31.4320825 C56.538824,30.3025638 56.5030884,29.4916774 56.5030884,28.9998828 C56.5030884,28.3304448 56.6785488,27.8080855 57.0288949,27.4331496 C57.3797007,27.0569498 57.8423093,26.869884 58.4146524,26.869884 Z"
                            id="error"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Error.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
};
Error.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
};

export default Error;
